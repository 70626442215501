import {
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import ReactPlayer from "react-player";

const Test = () => {
  const [playing, setPlaying] = useState(true);
  const [durationSeconds, setDurationSeconds] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1); // State for playback speed
  const playerRef = useRef();
  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        controls={false}
        playing={playing}
        playbackRate={playbackRate} // Set playback speed
        url="https://mashaal.b-cdn.net/1.mp4"
        onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
        onSeek={setPlayedSeconds}
        onDuration={setDurationSeconds}
      />
      <Controls
        playerRef={playerRef}
        playing={playing}
        setPlaying={setPlaying}
        playedSeconds={playedSeconds}
        duration={durationSeconds}
        playbackRate={playbackRate}
        setPlaybackRate={setPlaybackRate}
      />
    </div>
  );
};

type ControlsProps = {
  playing: boolean,
  setPlaying: Dispatch<SetStateAction<boolean>>,
  playedSeconds: number,
  duration: number,
  playerRef: MutableRefObject<ReactPlayer>,
  playbackRate: number,
  setPlaybackRate: Dispatch<SetStateAction<number>>,
};

const Controls = (props: ControlsProps) => {
  const seek = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.playerRef.current.seekTo(+e.target.value, "seconds");
  };

  return (
    <div>
      <button onClick={() => props.setPlaying(!props.playing)}>
        {props.playing ? "Pause" : "Play"}
      </button>
      <input
        type="range"
        value={props.playedSeconds}
        min="0"
        max={props.duration}
        onChange={seek}
      />
      <div>
        <button onClick={() => props.setPlaybackRate(0.5)}>0.5x</button>
        <button onClick={() => props.setPlaybackRate(1)}>1x</button>
        <button onClick={() => props.setPlaybackRate(1.5)}>1.5x</button>
        <button onClick={() => props.setPlaybackRate(1.75)}>1.75x</button>
        <button onClick={() => props.setPlaybackRate(2)}>2x</button>
      </div>
    </div>
  );
};

export default Test;
